<template>
    <div class="container-fluid vh-100">
        <slot />
    </div>
</template>

<script setup lang="ts">
useHead({
    htmlAttrs: {
        'data-bs-theme': 'dark',
    },
})
</script>
